import { getters as authGetters } from '../auth.service';
import { getters as subscriptionGetters } from '../subscription/subscription.service';
import { getBusinessAccountStatus } from '../account/account.service';
import { getters as tenantSettingsGetters } from '../tenant-settings/tenant-settings.service';
import { reactive, readonly } from 'vue';
import { hasPermission } from '../../utils/permissions.js';

const _state = reactive({
    canSeeAdvancedInsights: null,
    hasAdvancedInsights: null,
});

/**
 * Determines whether the logged in user is of account or sub-account level and has permission to see the advanced insights section.
 * @returns {boolean}
 */
const isAccountOrSubAccountUserWithAdvancedInsigthsPermission = () => {
    return (
        !authGetters.isTenantLevelUser &&
        hasPermission('ACCOUNT:BUSINESS_PORTAL_READ')
    );
};

/**
 * Read only state holding the information in `_state` to be consumed from the outside
 * @returns {Object}
 */
export const state = readonly(_state);

/**
 * Determines whether the logged in user is an account/sub-account level user and can see advanced insights section
 * in the main navigation.
 * @returns {boolean}
 */

export const canSeeAdvancedInsights = async () => {
    const response =
        isAccountOrSubAccountUserWithAdvancedInsigthsPermission() &&
        (tenantSettingsGetters.isTenantAccountBased ||
            (await getBusinessAccountStatus()));

    _state.canSeeAdvancedInsights = response;

    return response;
};

/**
 * Determines whether the logged in user is an account/sub-account level user and has the advanced insights section
 * enabled in their subscription.
 * @returns {boolean}
 */

export const hasAdvancedInsights = async () => {
    const response =
        (await canSeeAdvancedInsights()) &&
        (subscriptionGetters.hasBusinessPortal ||
            !subscriptionGetters.isAccountBasedSubscription);

    _state.hasAdvancedInsights = response;

    return response;
};
