<template>
    <div class="evo-flex evo-flex-col evo-flex-1 evo-h-full">
        <jolt-navigation
            v-if="isBusinessAccount !== null"
            :is-small="isSmall"
            :is-collapsible="false"
            :sections="sections"
            :logo-collapsed="logoCollapsed"
            :logo-expanded="logoExpanded"
            root-url="/"
            :auxiliary-nav-config="auxiliaryNavConfig"
            :primary-nav-z-index="30"
            class="evo-flex-1"
            :class="{ 'custom-evbox-branding': evboxTenantBranding }"
            main-el-classes="evo-flex-grow evo-flex evo-flex-col evo-min-h-full evo-flex-1 evo-bg-neutral-2 main-width-limit"
        >
            <template
                #primaryNavLink="{ data: { item, classes, navExpanded } }"
            >
                <component
                    :is="item.link ? 'a' : 'router-link'"
                    v-if="item.isVisible"
                    :to="{ name: item.name, params: { ...item.params } }"
                    :href="item.link"
                    :class="classes"
                >
                    <jolt-primary-nav-section-item
                        v-if="item.isVisible"
                        :icon="item.icon"
                        :label="$t(item.label)"
                        :show-label="navExpanded"
                    >
                        <template v-if="item.isLocked" #after-label>
                            <span class="lock-icon">
                                <jolt-icon
                                    icon-id="icon-lock"
                                    size="s"
                                ></jolt-icon>
                            </span>
                        </template>
                    </jolt-primary-nav-section-item>
                </component>
            </template>
            <template #auxiliaryNavLink="{ data: { item, classes } }">
                <span v-if="item.isVisible">
                    <router-link
                        v-if="item.name"
                        :to="{ name: item.name }"
                        :class="classes"
                    >
                        <jolt-primary-nav-section-item
                            :icon="item.icon"
                            :label="$t(item.label)"
                        ></jolt-primary-nav-section-item>
                    </router-link>
                    <a v-else :class="classes" :href="item.link">
                        <jolt-primary-nav-section-item
                            :icon="item.icon"
                            :label="$t(item.label)"
                        ></jolt-primary-nav-section-item>
                    </a>
                </span>
            </template>
            <template
                #auxiliaryNavProfileMenuLink="{ data: { item, classes } }"
            >
                <button
                    :class="[classes, 'evo-w-full evo-text-left']"
                    :href="item.url"
                    :target="item.isExternal ? '_blank' : null"
                    @click="item.onClick() || null"
                >
                    <jolt-primary-nav-section-item
                        :icon="item.icon"
                        :label="item.label"
                    ></jolt-primary-nav-section-item>
                </button>
            </template>
            <div
                class="page-content evo-flex evo-flex-1 evo-flex-col evo-h-full lg:evo-px-l evo-px-m"
            >
                <slot></slot>
            </div>
        </jolt-navigation>
    </div>
</template>

<script>
import {
    logout,
    state,
    getters as authGetters,
} from '../services/auth.service';
import { icon, logo, evboxTenantBranding } from '../services/config.service';
import {
    hasAnyPermission,
    hasPermission,
    hasAllPermissions,
} from '../utils/permissions';
import { getBusinessAccountStatus } from '../services/account/account.service';
import { getters as tenantSettingsGetters } from '../services/tenant-settings/tenant-settings.service.js';
import { SUBSCRIPTIONS } from '../core/constants';
import {
    subscriptionState,
    getters as subscriptionGetters,
} from '../services/subscription/subscription.service.js';
import { get } from '../services/local-store/local-store.service';
import {
    hasAdvancedInsights,
    state as loginState,
} from '../services/mixins/advanced-insights-mixin.service.js';

const permissionsToSeeTenantSection = [
    'TENANT:UPDATE',
    'BRANDING:UPDATE',
    'BILLING_PLAN:UPDATE',
];
const permissionsToSeeStationsItem = [
    'STATION:READ',
    'STATION:DASHBOARD_READ',
    'CHARGING_PROFILE:READ',
];

export default {
    name: 'private-layout',
    data() {
        return {
            noLeftNav: false,
            primaryNavSections: [],
            isSmall: false,
            isCollapsible: false,
            evboxTenantBranding: evboxTenantBranding(),
            isBusinessAccount: null,
            subscriptionState,
        };
    },
    computed: {
        icon,
        logo,
        currentRoute() {
            return this.$route.name || '';
        },
        canSeeAdvancedInsights() {
            return loginState.canSeeAdvancedInsights;
        },
        hasBusinessPortal() {
            return loginState.hasAdvancedInsights;
        },
        canSeeDiscountGroups() {
            // TODO: We will consolidate access logic in a separate file (TBD)
            const isCustomOffering =
                subscriptionState.subscriptionType ===
                SUBSCRIPTIONS.TYPES.CUSTOM_OFFERING;
            const hasPublicCharging =
                subscriptionState.subscription?.accountSubscription?.features
                    ?.stationPublicCharging?.enabled;

            if (isCustomOffering && !hasPublicCharging) {
                return false;
            }

            if (
                !get('$enableDiscountGroups$') ||
                !tenantSettingsGetters.isTenantAccountBased ||
                (!authGetters.isTenantLevelUser &&
                    !isCustomOffering &&
                    !subscriptionGetters.isAccountBasedSubscription)
            ) {
                return false;
            }

            return true;
        },
        hasDiscountGroups() {
            if (!this.canSeeDiscountGroups) {
                return false;
            }

            return (
                authGetters.isTenantLevelUser ||
                subscriptionState.subscription?.accountSubscription?.features
                    ?.stationPublicCharging?.enabled
            );
        },
        insightsMenuItems() {
            return {
                header: this.$t(
                    'generic.navigation.main.section.advancedInsights'
                ),
                items: [
                    {
                        label: this.$t('sites.insights.label'),
                        icon: 'icon-business',
                        isVisible: true,
                        name: this.hasBusinessPortal
                            ? 'auth.sites.insights'
                            : 'auth.advanced-insights.upsell',
                        isLocked: !this.hasBusinessPortal,
                    },
                    {
                        label: this.$t('fleets.insights.label'),
                        icon: 'icon-fleets-view',
                        isVisible: true,
                        name: this.hasBusinessPortal
                            ? 'auth.fleets.insights'
                            : 'auth.advanced-insights.upsell',
                        isLocked: !this.hasBusinessPortal,
                    },
                ],
            };
        },
        stationMenuItem() {
            if (!hasAnyPermission(permissionsToSeeStationsItem)) {
                return {};
            }

            return {
                label: this.$t('generic.entity.stations'),
                icon: 'icon-evse',
                items: [
                    {
                        label: 'stations.list.title',
                        name: 'stations',
                        isActive:
                            this.currentRoute.includes('stations-dashboard') ||
                            this.currentRoute.includes('stations-list'),
                        isVisible: hasAnyPermission([
                            'STATION:READ',
                            'STATION:DASHBOARD_READ',
                        ]),
                    },
                    {
                        label: 'stations.chargingProfiles.form.heading',
                        name: 'auth.stations.charging-profiles',
                        isActive:
                            this.currentRoute.includes('charging-profiles'),
                        isVisible: hasPermission('CHARGING_PROFILE:READ'),
                    },
                    {
                        label: 'generic.title.discounts',
                        name: this.hasDiscountGroups
                            ? 'auth.stations.discounts'
                            : 'auth.discounts.upsell',
                        isActive: this.currentRoute.includes('discounts'),
                        isVisible:
                            this.canSeeDiscountGroups &&
                            hasPermission('TARIFF_DISCOUNT_GROUP:READ'),
                        isLocked: !this.hasDiscountGroups,
                    },
                ],
            };
        },
        accountMenuItem() {
            if (
                !hasAnyPermission([
                    'ACCOUNT:READ_PERSONAL_INFO',
                    'ACCOUNT:READ_ALL',
                ])
            ) {
                return { isVisible: false };
            }

            const allowedToAddSubAccountUnderOwn =
                authGetters.isAccountLevelUser &&
                hasPermission('ACCOUNT:CREATE');

            if (
                allowedToAddSubAccountUnderOwn &&
                subscriptionGetters.isAccountBasedSubscription &&
                tenantSettingsGetters.isTenantAccountBased
            ) {
                return {
                    label: this.$t('generic.entity.accounts'),
                    icon: 'icon-accounts',
                    name: 'auth.accounts-setup',
                    isVisible: true,
                    items: [
                        {
                            label: 'generic.title.myAccount',
                            name: 'auth.accounts.my-account',
                            isActive: this.currentRoute.includes(
                                'auth.accounts.my-account'
                            ),
                            isVisible: true,
                        },
                        {
                            label: 'generic.title.subAccounts',
                            name: 'auth.sub-accounts-list',
                            isActive:
                                this.currentRoute.includes('sub-accounts'),
                            isVisible: this.isBusinessAccount,
                        },
                    ],
                };
            }

            if (
                hasAllPermissions([
                    'ACCOUNT:READ_PERSONAL_INFO',
                    'ACCOUNT:READ_ALL',
                ])
            ) {
                return {
                    label: 'generic.entity.accounts',
                    icon: 'icon-accounts',
                    name: 'auth.accounts-setup',
                    isActive: this.currentRoute.includes('accounts'),
                    isVisible: true,
                };
            }

            return { isVisible: false };
        },
        tenantMenuItems() {
            return {
                header: this.$t('generic.navigation.main.section.tenant'),
                items: [
                    {
                        label: this.$t('generic.title.settings'),
                        icon: 'icon-settings',
                        isVisible: hasPermission('TENANT:UPDATE'),
                        items: [
                            {
                                label: 'tenantSettings.languages.title',
                                name: 'auth.tenant-settings-languages',
                                isActive:
                                    this.currentRoute ===
                                    'auth.tenant-settings-languages',
                                isVisible: hasPermission('TENANT:UPDATE'),
                            },
                            {
                                label: 'generic.entity.legalEntities',
                                name: 'auth.tenant-settings-companies-list',
                                isActive: this.currentRoute.includes(
                                    'tenant-settings-companies'
                                ),
                                isVisible: hasPermission('TENANT:UPDATE'),
                            },
                            {
                                label: 'generic.entity.countries',
                                name: 'auth.tenant-settings-countries-list',
                                isActive: this.currentRoute.includes(
                                    'tenant-settings-countries'
                                ),
                                isVisible: hasPermission('TENANT:UPDATE'),
                            },
                            {
                                label: 'tenantSettings.supportLinks.title',
                                name: 'auth.tenant-settings-support-links',
                                isActive:
                                    this.currentRoute ===
                                    'auth.tenant-settings-support-links',
                                isVisible: hasPermission('TENANT:UPDATE'),
                            },
                            {
                                label: 'tenantSettings.notifications.title',
                                name: 'auth.tenant-settings-notifications',
                                isActive:
                                    this.currentRoute ===
                                    'auth.tenant-settings-notifications',
                                isVisible: hasPermission('TENANT:UPDATE'),
                            },
                        ],
                    },
                    {
                        label: this.$t('branding.title'),
                        icon: 'icon-branding',
                        name: 'auth.branding',
                        isActive: this.currentRoute === 'auth.branding',
                        isVisible: hasPermission('BRANDING:UPDATE'),
                    },
                    {
                        label: this.$t('cards.card.overview.heading.billing'),
                        icon: 'icon-billing',
                        isVisible: hasPermission('BILLING_PLAN:READ'),
                        items: [
                            {
                                label: 'billingPlans.products.title',
                                name: 'billing-plans-products',
                                isActive: this.currentRoute.includes(
                                    'billing-plans-products'
                                ),
                                isVisible: hasPermission('BILLING_PLAN:READ'),
                            },
                            {
                                label: 'billingPlans.plans.title',
                                name: 'billing-plans-plans',
                                isActive: this.currentRoute.includes(
                                    'billing-plans-plans'
                                ),
                                isVisible: hasPermission('BILLING_PLAN:READ'),
                            },
                        ],
                    },
                ],
            };
        },
        filterTenantMenuItems() {
            const filtered = this.tenantMenuItems.items.filter((i) => {
                if (i.items) {
                    return i.items.some((i) => {
                        return i.isVisible;
                    });
                }

                return i;
            });

            return {
                header: this.$t('generic.navigation.main.section.tenant'),
                items: [...filtered],
            };
        },
        mainMenuItems() {
            return [
                {
                    items: [
                        {
                            label: this.$t('dashboard.title'),
                            icon: 'icon-dashboard',
                            isVisible: this.canSeeAdvancedInsights,
                            name: this.hasBusinessPortal
                                ? 'auth.sites.dashboard'
                                : 'auth.advanced-insights.upsell',
                            isLocked: !this.hasBusinessPortal,
                        },
                        { ...this.stationMenuItem },
                        {
                            label: 'generic.entity.cards',
                            icon: 'icon-charge-card',
                            name: 'auth.cards.list',
                            isActive: this.currentRoute.includes('auth.cards'),
                            isVisible: hasPermission('CARD:READ'),
                        },
                        { ...this.accountMenuItem },
                        {
                            label: 'generic.entity.users',
                            icon: 'icon-users',
                            name: 'auth.users',
                            params: { type: 'system-users' },
                            isActive: this.currentRoute.includes('users'),
                            isVisible: hasAnyPermission([
                                'SYSTEM_USER:READ_ALL',
                                'CUSTOMER:READ_ALL',
                                'USER_GROUP:READ_ALL',
                            ]),
                        },
                    ],
                },
            ];
        },
        sections() {
            let menuItems = this.mainMenuItems;

            if (this.canSeeAdvancedInsights) {
                menuItems = menuItems.concat(this.insightsMenuItems);
            }

            if (hasAnyPermission(permissionsToSeeTenantSection)) {
                menuItems = menuItems.concat(this.filterTenantMenuItems);
            }

            return menuItems;
        },
        logoCollapsed() {
            return this.icon;
        },
        logoExpanded() {
            return this.logo;
        },
        auxiliaryNavConfig() {
            const initials = `${state.profile.firstName.substring(
                0,
                1
            )}${state.profile.lastName.substring(0, 1)}`;

            const menuItems = [
                {
                    label: this.$t('generic.entity.profile'),
                    onClick: () => this.$router.push({ name: 'auth.profile' }),
                    icon: 'icon-consumer-account',
                },
                {
                    label: this.$t('support.title'),
                    onClick: () => this.$router.push({ name: 'auth.support' }),
                    icon: 'icon-help',
                },
                {
                    label: this.$t('logout.title'),
                    icon: 'icon-log-out',
                    onClick: logout,
                },
            ];

            if (!authGetters.isTenantLevelUser) {
                menuItems.splice(1, 0, {
                    label: this.$t('generic.entity.account'),
                    onClick: () =>
                        this.$router.push({ name: 'auth.accounts.my-account' }),
                    icon: 'icon-accounts',
                });
            }

            return {
                userInitial: initials,
                profileMenu: menuItems,
                zIndex: 21,
            };
        },
    },
    watch: {
        'subscriptionState.subscription': {
            deep: true,
            immediate: true,
            handler(newValue) {
                // When the subscription changes we need to refresh the advanced insights
                // state check, so the left nav updates the locked items status.
                if (newValue) {
                    hasAdvancedInsights();
                }
            },
        },
    },
    async created() {
        if (!authGetters.isTenantLevelUser) {
            this.isBusinessAccount = await getBusinessAccountStatus();
        } else {
            this.isBusinessAccount = false;
        }
    },
    mounted() {
        this.handlePageResize();
        window.addEventListener('resize', this.handlePageResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handlePageResize);
    },
    methods: {
        handlePageResize() {
            this.isSmall = window.innerWidth <= 992;
        },
    },
};
</script>

<style scoped>
@media (min-width: 640px) {
    .page-content {
        max-width: calc(100% - 2 * var(--spacing-m));
    }
}

:deep(.j-logo.j-primary-nav__logo) {
    margin: 0;
}

:deep(.j-primary-nav__panel) {
    padding: 0;
}

:deep(.custom-evbox-branding .j-primary-nav > .j-primary-nav__panel) {
    background-color: var(--color-brand);
}

:deep(.custom-evbox-branding .j-primary-nav__nav-wrapper) {
    background-color: var(--j-color-neutral-1);
}

:deep(.main-width-limit) {
    max-width: 100vw;
}

@media (min-width: 992px) {
    :deep(.main-width-limit) {
        --primary-nav-collapsed-width: calc(4.25 * var(--spacing-m));
        --primary-nav-expanded-width: calc(16 * var(--spacing-m));
        max-width: calc(100vw - var(--primary-nav-expanded-width));
    }
}

.lock-icon {
    background-color: var(--color-feedback-info-light) !important;
    border-radius: 50%;
    color: var(--color-feedback-info-dark);
    display: inline-flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
}

.lock-icon :deep(svg) {
    width: 0.8rem;
    height: 0.8rem;
}
</style>
