import { hasAllPermissions, hasAnyPermission } from '../utils/permissions';
import { getters as auth } from '../services/auth.service';
import { get, set, remove } from '../services/local-store/index.js';
import { configuration } from '../services/config.service';
import { state as loginState } from '../services/mixins/advanced-insights-mixin.service.js';

export const DEFAULT_PUBLIC_ROUTE_NAME = 'login';
export const DEFAULT_AUTHENTICATED_ROUTE_NAME = 'stations';
export const ADVANCED_AUTHENTICATED_ROUTE_NAME = 'auth.sites.dashboard';
const redirectStorageKey = 'redirectAfterLoginTo';

export const DEFAULT_FORBIDDEN_ROUTE_NAME = 'auth.forbidden';

// eslint-disable-next-line complexity
const getRedirectRoute = (to) => {
    if (to.meta?.noRedirect) {
        return to;
    }

    if (!configuration.tenant) {
        setRedirectStorageValue(to);

        return to;
    }

    if (!auth.isAuthenticated && !to.meta?.public) {
        setRedirectStorageValue(to);

        return getPublicDefaultRoute(to);
    }

    if (auth.isAuthenticated) {
        if (!auth.isSetupComplete) {
            return { name: 'auth.welcome' };
        }

        if (!hasAccessToRoute(to)) {
            remove(redirectStorageKey);

            return (
                to.meta?.redirect || {
                    name: DEFAULT_FORBIDDEN_ROUTE_NAME,
                }
            );
        }

        const previousRedirectRoute = get(redirectStorageKey);

        remove(redirectStorageKey);

        if (previousRedirectRoute?.name) {
            return previousRedirectRoute;
        }
    }

    if (auth.isAuthenticated && isUnavailableRoute(to)) {
        return loginState.hasAdvancedInsights
            ? { name: ADVANCED_AUTHENTICATED_ROUTE_NAME }
            : { name: DEFAULT_AUTHENTICATED_ROUTE_NAME };
    }

    return to;
};

function setRedirectStorageValue(to) {
    if (!to.name) {
        remove(redirectStorageKey);
    }

    if (!to.meta?.public && to.name !== DEFAULT_AUTHENTICATED_ROUTE_NAME) {
        // Make a copy of the item and remove matched to avoid cyclical error in localStore service
        const item = { ...to };

        item.matched = {};

        set(redirectStorageKey, item);
    }
}

const getPublicDefaultRoute = (to) => {
    const query =
        to.name !== DEFAULT_PUBLIC_ROUTE_NAME && to.name
            ? { redirect: to.name }
            : null;

    return { name: DEFAULT_PUBLIC_ROUTE_NAME, query };
};

const isUnavailableRoute = (to) => {
    return (
        to.matched.length === 0 ||
        to.matched.some((record) => record.meta?.public)
    );
};

function getPermissionCheckMethod(meta) {
    const permissionsOperator = meta?.permissionsOperator;

    switch (permissionsOperator) {
        case 'OR':
            return hasAnyPermission;
        default:
            return hasAllPermissions;
    }
}

/**
 * Whether the current user has access to the given route
 *
 * Checks two things:
 * 1. Does the user have the required permissions?
 * 2. Is the route available?
 *
 * @param {Object} route
 * @returns {boolean}
 */
export function hasAccessToRoute(route) {
    const permissions = route.meta?.permissions || [];
    const permissionCheckMethod = getPermissionCheckMethod(route.meta);

    // If a route has no special conditions, it's considered always available.
    const isAvailable = route.meta?.isAvailable ?? (() => true);

    return permissionCheckMethod(permissions) && isAvailable();
}

export default getRedirectRoute;
