import { createRouter, createWebHistory } from 'vue-router';
import getRedirectRoute from './router.auth';

// local or prod (no adhoc uri segment)
let baseURL = `/`;

if (EVERON_DEPLOY_ENV) {
    baseURL = `/${EVERON_DEPLOY_ENV}`;
}

const router = createRouter({
    history: createWebHistory(baseURL),
    routes: [],
});

export const checkRoute = (to) => {
    // @TODO: THIS IF FOR MOBILE EMBEDDED WORKAROUND
    if (to.query.state) {
        window.location.href = to.query.state;
    }

    const nextRoute = getRedirectRoute(to);

    if (nextRoute.name !== to.name) {
        return nextRoute;
    }
};

router.beforeEach(checkRoute);

router.afterEach((to) => {
    document.title = to.meta?.title || 'Everon Portal';
});

export default router;
