import apiClient from '../core/api/api-client';
import { API_ROUTES, USER_LEVELS } from '../core/constants';
import OktaService from './okta.service';
import { reactive } from 'vue';
import { apiUrl } from '../core/api/apiUrl';
import { getters as config } from './config.service';
import {
    getSubscriptionForLoggedInUser,
    getSubscriptionTypeForLoggedInUser,
} from './subscription/subscription.service.js';
import { getFeatureFlags } from './tenant-settings/tenant-settings.service.js';
import { hasPermission } from '../utils/permissions.js';
import { accountSetupCompleteHook } from '../composables/useAccountSetup.js';

export const state = reactive({
    profile: null,
    isAuthenticated: false,
});

export const getters = {
    get isAuthenticated() {
        return state.isAuthenticated || false;
    },
    get permissions() {
        return state.profile.permissions || [];
    },
    /**
     * Returns whether the logged-in user is a tenant level user.
     * A tenant level user has `TENANT` access or is member of a support team (a.k.a. expert access - which access level
     * is `MULTIPLE_ACCOUNTS`).
     * @returns {boolean}
     */
    get isTenantLevelUser() {
        const hasTenantAccess = state.profile.access === 'TENANT';

        return hasTenantAccess || this.isMemberOfSupportTeam;
    },
    /**
     * Returns whether the logged-in user is an account level user.
     * An account level user has `MULTIPLE_ACCOUNTS` access and is not member of a support team (a.k.a. expert access -
     * which access level is also `MULTIPLE_ACCOUNTS`).
     * @returns {boolean}
     */
    get isAccountLevelUser() {
        const hasMultipleLevelAccess =
            state.profile.access === 'MULTIPLE_ACCOUNTS';

        return hasMultipleLevelAccess && !this.isMemberOfSupportTeam;
    },
    /**
     * Returns whether the logged-in user is a sub-account level user.
     * A sub-account level user has `SINGLE_ACCOUNTS` access.
     * @returns {boolean}
     */
    get isSubAccountLevelUser() {
        return state.profile.access === 'SINGLE_ACCOUNT';
    },
    /**
     * Returns the level of the current logged-in user.
     * The current levels are: tenant, account, and sub-account.
     * @returns {string}
     */
    get userLevel() {
        if (this.isTenantLevelUser) {
            return USER_LEVELS.TENANT;
        }

        if (this.isAccountLevelUser) {
            return USER_LEVELS.ACCOUNT;
        }

        return USER_LEVELS.SUB_ACCOUNT;
    },
    /**
     * Returns whether the logged-in user is a driver by checking the access level and the ability to invite users.
     * A driver user has `SINGLE_ACCOUNT` level access and cannot invite users.
     * @returns {boolean}
     */
    get isDriver() {
        return this.isSubAccountLevelUser && !hasPermission('USER:CREATE');
    },
    /**
     * Returns whether the logged-in user is member of a support team (a.k.a. expert access)
     * @returns {boolean}
     */
    get isMemberOfSupportTeam() {
        return Boolean(state.profile?.groups?.length);
    },
    get isSetupComplete() {
        return Boolean(state.profile?.accountSetupComplete);
    },
};

export const updateProfileData = (payload) => {
    state.profile = {
        ...state.profile,
        ...payload,
    };
};

export const checkSession = async () => {
    const userId = await OktaService.getUserId();

    if (userId) {
        const url = API_ROUTES.USERS.GET.replace('{userId}', userId);
        const userDetails = await apiClient.get(url);
        const userPermissions = await OktaService.getUserPermissions();
        const { payload: tokenInfo } = await OktaService.getOktaTokenDecoded();
        const accountSetup = await accountSetupCompleteHook();

        state.profile = {
            ...userDetails,
            roles: userDetails.roles.map((role) =>
                role
                    .toLowerCase()
                    .replace(/_([a-z])/g, (g) => g[1].toUpperCase())
            ),
            groups: tokenInfo.groups,
            groupsAdmin: tokenInfo.groupsAdmin,
            oktaId: tokenInfo.uid,
            accountId: tokenInfo.accountId,
            access: tokenInfo.access,
            permissions: userPermissions,
            accountSetupComplete: accountSetup.isSetupComplete,
        };

        state.isAuthenticated = true;

        return state.profile;
    } else {
        state.isAuthenticated = false;
    }

    return null;
};

export const logout = () => {
    return OktaService.signOut();
};

export const login = async ({ email, password }) => {
    try {
        const session = await OktaService.signIn(email, password);

        const profile = await checkSession();

        await getFeatureFlags();

        if (profile && !getters.isTenantLevelUser) {
            await Promise.all([
                getSubscriptionTypeForLoggedInUser(),
                getSubscriptionForLoggedInUser(),
            ]);
        }

        return !!session;
    } catch (error) {
        console.error(error);

        return false;
    }
};

export const requestPasswordReset = (email) => {
    const body = { email };

    const headers = {
        'Content-Type': 'application/json',
    };

    if (config.tenantId) {
        headers.tenantId = config.tenantId;
    }

    return fetch(`${apiUrl}${API_ROUTES.USERS.INITIATE_PASSWORD_RESET}`, {
        referrerPolicy: 'no-referrer-when-downgrade',
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify(body),
    }).then((response) => {
        if (!response.ok) {
            throw Error(`Error ${response.status}`);
        }

        return response;
    });
};

export const verifyPasswordCode = (recoveryToken) => {
    return apiClient.post(
        API_ROUTES.USERS.VERIFY_PASSWORD_CODE,
        { recoveryToken },
        {
            isPublic: true,
        }
    );
};

export const resetPassword = ({ password, stateToken }) => {
    return apiClient.patch(
        API_ROUTES.USERS.RESET_PASSWORD,
        { password, stateToken },
        {
            isPublic: true,
        }
    );
};

export const signUp = (data) => {
    return apiClient.post(API_ROUTES.USERS.SIGN_UP, data, {
        isPublic: true,
    });
};

export const verifyActivationCode = (activationCode) => {
    return apiClient.get(API_ROUTES.USERS.VERIFY_ACTIVATION_CODE, {
        params: {
            activationCode,
        },
        isPublic: true,
    });
};

export const activateProfile = (token) => {
    const headers = {
        'Content-Type': 'application/json',
    };

    if (config.tenantId) {
        headers.tenantId = config.tenantId;
    }

    return fetch(`${apiUrl}${API_ROUTES.USERS.ACTIVATE_PROFILE}`, {
        referrerPolicy: 'no-referrer-when-downgrade',
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify({ token }),
    }).then((response) => {
        if (!response.ok) {
            throw Error(`Error ${response.status}`);
        }

        return response.json();
    });
};

export const updateInvitedAccount = (data, stateToken) => {
    const { password, languageTag, countryCode, ...rest } = data;

    return apiClient.post(API_ROUTES.USERS.SETUP_PROFILE, {
        stateToken,
        password,
        countryCode,
        profile: {
            language: languageTag,
            ...rest,
        },
    });
};

export const activateSelfSignUp = (activationCode) => {
    return apiClient.get(API_ROUTES.USERS.ACTIVATE_SELF_SIGN_UP, {
        params: {
            activationCode,
        },
        isPublic: true,
    });
};

/**
 * Activates an account (or profile) when the user signed up by themselves and click the confirmation link received by email.
 * @param {string} code
 * @returns {Promise}
 */
export const activateAccount = (code) => {
    return apiClient.get(`/api/users/signup/activation/${code}`);
};
